import React from 'react';


function LandingPage(){

    return (
        <div style={{textAlign: 'center', margin: 'auto'}}>
            <img alt="El Locale Logo" src="./ellocale_wide.png"/>
        </div>
    );
}

export default LandingPage;