import React from "react";

import "./interest.css";

function InterestComplete(props) {

    return (
        <React.Fragment>
            <div className={"interest-override-height interest-padtop interest-padsides interest-font-l"}>
                An email has been sent to the email address you provided.  <br/><br/>Please click the link to verify your interest in ElLocale!
            </div>
            <div className={"interest-padbottom interest-button-font"}>
                Tell your friends and family!
            </div>
        </React.Fragment>
    );
}

export default InterestComplete;