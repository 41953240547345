
import React from 'react';

import "./SplashPage.css";
import {Grid} from "@mui/material";
import Interest from "../../bundles/interest/interest";
import SupportLocal from "../../bundles/support/support";

import ShopLocal from "../../images/shop-local.png";
import ElLocale from "../../images/ellocale_text_only.png";
import BackgroundImage from "../../components/image/BackgroundImage";

import IMAGE from "../../images/portsmith-image.jpg";
import MissionStatement from "../../bundles/mission/mission";
import ElFooter from "../../components/footer/elfooter";

class SplashPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className={"splash-container"}>
                    <div className={"splash-img-margins"}>
                        <img className={"splash-ellocale-img"} alt={"El Locale"} src={ElLocale}/>
                    </div>
                    <div className={"splash-upmargin"}>
                        <Grid container alignContent={"center"} textAlign={"center"}>
                            <Grid item sm={12} md={7}>
                                <div className={"splash-font-info splash-textpane-width splash-margins splash-margin-top"}>Welcome to the place where you easily shop local</div>
                                <div className={"splash-margin-top"}><img className={"splash-shop-local-img"} src={ShopLocal} alt={"Shop Local"}/></div>
                                <div className={"splash-margins splash-margin-top splash-margin-bottom"}><SupportLocal/></div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Interest/>
                            </Grid>
                        </Grid>
                    </div>
                    <BackgroundImage image={IMAGE} height={200} content={<MissionStatement/>}/>
                    <ElFooter/>
                </div>
            </React.Fragment>
        );
    }
}

export default SplashPage;