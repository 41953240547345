import {Box, Button, Divider, TextField} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import React, {useState} from "react";
import {doFetch, fetchUIInstance} from "../../themes/ElLocaleTheme";

import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import {initializeApp} from "firebase/app";
import GoogleOneTapLogin from "react-google-one-tap-login";

const INTEREST_INVALID_EMAIL = "Email is not valid";
const INTEREST_INVALID_NAME = "Full Names must be at least 5 characters."

function InterestForm(props) {

    const GOOGLE_API_KEY = "AIzaSyB_qP8kdBWdIMYFgYIAzxww-S7YqUjkGHQ";
    const GOOGLE_AUTH_DOMAIN = "ellocale.firebaseapp.com";
    const GOOGLE_CLIENT_ID = "498103691143-c2jsm9kcvivvme4am0arut8q4gd7ldmp.apps.googleusercontent.com";

    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [loginWithGmail, setLoginWithGmail] = useState(false);

    console.log(loginWithGmail);

    function handleGoogleSuccess(response) {

        let payload = {
            email: response.email,
            fullname: response.given_name + " " + response.family_name
        };

        doFetch("interest/create", payload, (response) => handleCreateInterest(response, payload.email));
    }

    function handleGoogleFailure(response) {
        alert("Failed to sign up with google.");
        setLoginWithGmail(false);
    }

    return ( <React.Fragment>
                <div className={"bold interest-font-large"}>Stay in the Know</div>
                <div className={"interest-width-large interest-aligned-center"}>
                <div className={"interest-margin-top-small interest-max-width interest-side-margins interest-font-small"}>sign up for launch updates and specials</div>
                <div className={"interest-margin-top-small"}>
                    <TextField
                        onBlur={() => doValidate()}
                        helperText={emailError}
                        error={emailError.length > 0}
                        size={"small"}
                        className={"interest-max-width interest-text-size"}
                        id={"interest_email"}
                        label={"Email Address"}
                        InputProps={{style: {fontSize: 14}}}
                        variant={"outlined"}/>
                </div>
                <div className={"interest-margin-top-small"}>
                    <TextField
                        name="fullname"
                        onBlur={() => doValidate()}
                        size={"small"}
                        className={"interest-max-width interest-text-size"}
                        id={"interest_name"}
                        helperText={nameError}
                        error={nameError.length > 0}
                        label={"Full Name"}
                        InputProps={{style: {fontSize: 14}}}
                        variant={"outlined"}/>
                </div>
                <div className={"interest-margin-top-small"}>
                    <Button size={"small"} className={"interest-max-width"} variant={"contained"} color="primary">
                        <div className={"interest-button-font"} onClick={() => submit()}>Sign Up</div>
                    </Button>
                </div>
                <div className={"interest-width-large interest-aligned-center interest-margin-top-small"}>
                    <Divider variant={"middle"} className={"interest-font-tiny"} textAlign={"center"}>
                        <div className={"interest-font"}>or sign up with</div>
                    </Divider>
                </div>
                <div>
                    <Box className={"interest-margin-top-small"}>
                        <Button
                            sx={{mr: 1}}
                            size="small"
                            color="standard"
                            className={"interest-button-font interest-button-width"}
                            variant="outlined"
                            startIcon={<GoogleIcon />}
                            onClick={() => setLoginWithGmail(true)}>
                            Google
                        </Button>
                        <Button disabled size="small" color="standard" variant="outlined" className={"interest-button-font interest-button-width"} startIcon={<FacebookIcon />}>
                            Facebook
                        </Button>
                    </Box>
                </div>
                <div className={"interest-font-tiny interest-margin-top interest-margin-bottom"}>
                                            <span className={"interest-small-margins interest-light-font-color"}>By signing up you agree to the
                                                <span className={"interest-fake-link"}> terms of service</span> and
                                                <span className={"interest-fake-link"}> privacy policy</span>
                                            </span>
                </div>
            </div>
            {

                loginWithGmail ? <GoogleOneTapLogin
                    onError={handleGoogleFailure}
                    onSuccess={handleGoogleSuccess}
                    googleAccountConfigs={{ client_id: GOOGLE_CLIENT_ID }} /> :
                    <div></div>
            }
        </React.Fragment>
    );

    function doValidate() {

        let validEmail = false;
        let fullname = document.getElementById("interest_name");
        let email = document.getElementById("interest_email");

        if (email != null) {
            if (email.value.length > 0 && (email.value.indexOf(".") === -1 || email.value.indexOf("@") === -1)) {

                setEmailError(INTEREST_INVALID_EMAIL);
            }
            else if (email.value.length > 0){
                setEmailError("");
                validEmail = true;
            }
        }

        if (fullname != null) {
            if (fullname.value.length > 0 && fullname.value.length < 5) {
                setNameError(INTEREST_INVALID_NAME);
            }
            else {
                setNameError("");
            }
        }

        if (validEmail) {
            validateEmailExists(email.value);
        }
    }

    function handleEmailResponse(response) {
        if (response.status === 'success') {
            setEmailError("This email has already registered");
        }
    }

    function validateEmailExists(val) {
        let payload = {
            email:val
        };

        doFetch("interest/exists", payload, (response) => handleEmailResponse(response));
    }

    function handleCreateInterest(response, email) {

        if (response.status === "success") {
            props.parent.setComplete(true);
            sendEmailLink(response.details, email);
        }
        else {
            setEmailError(response.details);
        }
    }

    function submit() {

        if (emailError.length === 0 && nameError.length === 0) {
            let email = document.getElementById("interest_email");
            let fullname = document.getElementById("interest_name");

            if (email.value.length > 0 && fullname.value.length > 0) {
                let payload = {
                    email: email.value,
                    fullname: fullname.value
                };

                doFetch("interest/create", payload, (response) => handleCreateInterest(response, email.value));
            }
        }
    }

    function sendEmailLink(id, email) {
        const actionCodeSettings = {
            url: fetchUIInstance() + "splash?interestId="+id,
            handleCodeInApp: true,
            dynamicLinkDomain: 'ellocale.page.link'
        };

        const config = {
            apiKey: GOOGLE_API_KEY,
            authDomain: GOOGLE_AUTH_DOMAIN
        };

        const app = initializeApp(config);
        const auth = getAuth(app);

        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                console.log("Successfully sent");
            })
            .catch((error) => {
                const errorMessage = error.message;

                console.log(errorMessage);
            });
    }
}

export default InterestForm;