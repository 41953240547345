import React from 'react';
import ElCard from "../../components/card/elcard";
function TestScreen(){

    return (
        <div style={{textAlign: 'center'}}>
            <div style={{fontSize: '40px', fontWeight: 'bold'}}>
                Test Portal
            </div>

            <div>
                Screens that are greyed out are not currently available for testing
            </div>

            <ElCard title={"Your Locale"} body={"This is the main page of the application.  " +
                "This is the entry point for the web application."}/>
            <ElCard route="/authtest" title={"Auth Tests"} body={"This is a test page used to confirm external identify platforms"}/>
            <ElCard title={"Header Bar"} route={"/header"} body={"This is the header that will appear at the top.  " +
                "It can be adjusted but should always contain a search bar."}/>
            <ElCard title={"Login Page"} route={"/login"} body={"This where users go to login.  " +
                "Users can login through a variety of canned means.  They can also choose to create an account."}/>
            <ElCard title={"Search Results"} body={"This page shows the bulk of the products available " +
                "in each 'locale' and any recommendations the app has deemed to show"}/>
            <ElCard title={"Community/Donation"} body={"This shows all the local charities in the region and provides " +
                "a mechanism to donate or support the local community in wasy TBD."}/>
            <ElCard title={"Subscriptions"} body={"This page shows subscriptions that can be purchased that will ultimately " +
                "benefit your 'locale'"}/>
            <ElCard title={"Shopping Cart"} body={"This page shows an aggregate of all the items you have " +
                "put in your cart from shops in your 'locale'"}/>
            <ElCard title={"Business Profile"} body={"This screen will show all data and properties specific to a " +
                "business in a 'locale' and will delineate all of the subscribed services, bookings, events, and integrations. "}/>
            <ElCard title={"Customer Profile"} body={"Contains all the personal data specific to a customer shared with " +
                "ElLocale, Ad preferences specific to the customer, and connections."}/>
            <ElCard title={"Locale Directory"} body={"Shows all the available shops/pois in the given locale.  Each poi " +
                "will generally have a link, image, and description"}/>
            <ElCard title={"Footer Bar"} route={"/footer"} body={"This is the footer that will appear at the bottom.  It will contain " +
                "relevant links."}/>
            <ElCard title={"Interest Form"} route={"/interest"} body={"This is the interest form for the splash page"}/>
            <ElCard title={"Splash Page"} route={"/splash"} body={"This is the splash page that exposes the Interst Form as well as the ELFooter"}/>
            <ElCard title={"Background Image Page"} route={"/bgimage"} body={"This is a quick view into the background image component"}/>
        </div>
    );
}

export default TestScreen;