
import {createTheme} from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
// const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

// this determines what cross origin endpoints we are using.  This needs to be automated as part of the
// deployment process
const DEBUG = true;
const STAGE = false;

export const doFetch = (uri, payload, handler) => {

    let instance = fetchServerInstance() + uri;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    fetch(instance, requestOptions)
        .then(response => response.json())
        .then(data => handler(data));
}

export const fetchServerInstance = (ui) => {
    if (DEBUG) {
        return "http://localhost:8080/";
    }
    else if (STAGE) {
        return "http://ellocale-dev.gibberinggate.com:8080";
    }
    else {
        return "https://ellocale.com:8080/";
    }
};

export const fetchUIInstance = () => {
    if (DEBUG) {
        return "http://localhost:3000/";
    }
    else if (STAGE) {
        return "http://ellocale-dev.gibberinggate.com/";
    }
    else {
        return "https://ellocale.com";
    }
}

export const THEME = createTheme({
    typography: {
        fontFamily: 'Montserrat',
        MuiButtonBase: {
            styleOverrides: {
                textTransform: 'none',
                fontFamily: "Montserrat",
                fontWeight: 700
            },
        }
    },
    palette: {
        primary: {
            light: "#ffbb11",
            main: "#ffbb11",
            dark: "#ffbb11",
            contrastText: "rgba(0, 0, 0, 1)"
        },
        standard: {
            light: "#000000",
            main: "#000000",
            dark: "#000000",
            contrastText: "rgba(0, 0, 0, 1)"
        }
    }
});
