import React from 'react';
import {Component} from "react";
import cartImage from "../../images/cart.png";

import "./elheader.css";

class ElHeader extends Component {

    props = null;

    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {

        return (
            <div>
                <div className={"header"}>
                    <div className={"footer-centered"}>
                        <div className={"footer-elloc"}>ElLocale</div>
                        <div><input className={"search"} type={"text"}/></div>
                        <div><img alt="Cart" className={"footer-icon"} src={cartImage}/></div>
                        <div className={"footer-other"}>Need Help?</div>
                        <div className={"footer-other footer-farright"}>Sign In</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ElHeader;