
import React from 'react'
import "./LoginPage.css"

import squareImage from "../../images/Square_Icon_64x64.png";
import cloverImage from "../../images/Clover_Icon_64x64.png";
import googleImage from "../../images/GooglePlus_Icon_64x64.png";
import toastImage from "../../images/Toast_Icon_64x64.png";
import facebookImage from "../../images/Facebook_Icon_64x64.png";
import {Button, CssBaseline, Divider, TextField, ThemeProvider} from "@mui/material";

import { THEME } from "../../themes/ElLocaleTheme";

class LoginPage extends React.Component{

    // constructor(props){
    //     super(props);
    // }

    render() {

        return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={THEME}>
                    <div className={"login-size login-bordered loginCentered1 loginMarginTop loginTextCenter"}>
                        <img alt="El Locale Logo" src={"ellocale_wide.png"} className={"login-image-div"}/>
                        <div className={"login-pad-top"}>
                            <TextField size={"small"} className={"login-box-size"} id={"username"} label={"username"} variant={"outlined"}/>
                        </div>
                        <div className={"login-pad-top-small"}>
                            <TextField size={"small"} className={"login-box-size"} id={"password"} label={"password"} variant={"outlined"}/>
                        </div>
                        <div className={"login-pad-top"}>
                            <Button size={"small"} className={"login-button login-box-size"} variant={"contained"} color="primary">
                                <div className={"login-button-font"}>login</div>
                            </Button>
                        </div>
                        <div className={"login-divider-width login-aligned-center login-divider-margin-top-small"}>
                            <Divider variant={"middle"} className={"interest-font-tiny"} textAlign={"center"}>
                                <div className={"interest-font"}>or sign up with</div>
                            </Divider>
                        </div>
                        <div className={"loginSmallSpacer"}>
                            <img className={"login-partner-icon login-icon-disabled"} src={cloverImage} alt={"clover"}/>
                            <img className={"login-partner-icon login-icon-disabled"} src={toastImage} alt={"toast"}/>
                            <img className={"login-partner-icon login-icon-disabled"} src={squareImage} alt={"square"}/>
                            <img className={"login-partner-icon login-icon-disabled"} src={facebookImage} alt={"facebook"}/>
                            <img className={"login-partner-icon"} src={googleImage} alt={"google"}/>
                        </div>
                        <div className={"loginMediumSpacer loginFontSizeSmall loginAccountCreate"}>
                            create account
                        </div>
                    </div>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default LoginPage;