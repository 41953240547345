
import React from 'react';
import {GoogleLogin} from "@react-oauth/google";

import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleOneTapLogin from 'react-google-one-tap-login';

import { useState } from 'react';

function TestScreen() {

    const [ login, setLogin ] = useState(false);

    function handleError(response) {
        console.log(response);
    }

    function handleSuccess(response) {
        console.log(response);
    }

    function doLogin() {
        setLogin(true);
    }

    return (

        <React.Fragment>
            {
                !login ? <div><button onClick={doLogin}>Click Here</button></div> :
                <GoogleOneTapLogin
                    onError={handleError}
                    onSuccess={handleSuccess}
                    googleAccountConfigs={{ client_id: "498103691143-c2jsm9kcvivvme4am0arut8q4gd7ldmp.apps.googleusercontent.com" }} />
            }
        </React.Fragment>
    );
}

export default TestScreen;