import React from 'react';
import {Component} from "react";

import "./elfooter.css";
import {Grid} from "@mui/material";

class ElFooter extends Component {

    props = null;

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {

        return (
            <div>
                <div className={"footer"}>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item md={4} sm={12} xs={12}>
                                <div className={"footer-title"}>CATEGORY</div>
                                <div className={"footer-links"}>
                                    <div>Art</div>
                                    <div>Clothing</div>
                                    <div>Furniture</div>
                                    <div>Jewelry</div>
                                    <div>Tea</div>
                                </div>
                            </Grid>
                            <Grid item item md={4} sm={12} xs={12}>
                                <div className={"footer-title"}>MY ACCOUNT</div>
                                <div className={"footer-links"}>
                                    <div>Sign-Up</div>
                                    <div>Login</div>
                                    <div>My Orders</div>
                                    <div>Cart</div>
                                </div>
                            </Grid>
                            <Grid item item md={4} sm={12} xs={12}>
                                <div className={"footer-title"}>INFORMATION</div>
                                <div className={"footer-links"}>
                                    <div>Contact Us</div>
                                    <div>About Us</div>
                                    <div>Report a Bug</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default ElFooter;