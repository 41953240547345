import React from "react";

import "./interest.css";

function InterestValidated(props) {

    return (
        <React.Fragment>
            <div className={"interest-override-height interest-padtop interest-padsides interest-font-l"}>
                Your interest in <div><img className={"interest-logo-size interest-logo-padding"} src={"./ellocale_short.png"}/></div>
                has been verified! <br/><br/>Thank you for signing up!

            </div>
            <div className={"interest-padbottom interest-button-font"}>
                Tell your friends and family!
            </div>
        </React.Fragment>
    );
}

export default InterestValidated;