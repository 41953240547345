import React from 'react';
import {CssBaseline, Grid, Paper, ThemeProvider} from "@mui/material";

import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';

import { THEME } from "../../themes/ElLocaleTheme";
import "./support.css";

class SupportLocal extends React.Component {

    render() {
        return  <React.Fragment>
                    <CssBaseline/>
                    <ThemeProvider theme={THEME}>
                        <Paper variant={"elevation"} elevation={0} sx={{ml: "20px", mr: "20px"}}>
                            <div className={"support-centered support-font-info support-margin-top"}>SUPPORT YOUR LOCAL BUSINESSES</div>
                            <div className={"support-centered"}>
                                <Grid container spacing={0} className={"support-maxwidth support-margin-top"} alignItems={"center"} justifyContent={"center"}>
                                    <Grid item textAlign={"center"} alignItems={"center"} justifyContent={"center"} xs={4} sm={4} md={4}>
                                        <Paper variant={"elevation"} elevation={0}>
                                            <div><StorefrontOutlinedIcon fontSize={"large"}/></div>
                                            <div className={"support-font-info support-font-size"}>Shops</div>
                                        </Paper>
                                    </Grid>
                                    <Grid item textAlign={"center"} alignItems={"center"} justifyContent={"center"} xs={4} sm={4}  md={4}>
                                        <Paper variant={"elevation"} elevation={0}>
                                            <div><ColorLensOutlinedIcon fontSize={"large"}/></div>
                                            <div className={"support-font-info support-font-size"}>Artisans</div>
                                        </Paper>
                                    </Grid>
                                    <Grid item textAlign={"center"} alignItems={"center"} justifyContent={"center"} xs={4} sm={4}  md={4}>
                                        <Paper variant={"elevation"} elevation={0}>
                                            <div><LocalCafeOutlinedIcon fontSize={"large"}/></div>
                                            <div className={"support-font-info support-font-size"}>Coffee Shops</div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </ThemeProvider>
                </React.Fragment>
    }
}

export default SupportLocal;