import './App.css';

import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {Routes,Route} from "react-router-dom";

import LandingPage from "./pages/landing/LandingPage";
import TestScreen from "./pages/tests/TestScreen";
import LoginPage from "./pages/login/LoginPage";
import ElHeader from "./components/header/elheader";
import ElFooter from "./components/footer/elfooter";
import ELInterest from "./bundles/interest/interest";
import SplashPage from "./pages/splash/SplashPage";
import ImageTester from "./components/image/BackgroundImage";
import BackgroundImage from "./components/image/BackgroundImage";

import IMAGE from "../src/images/portsmith-image.jpg";
import AuthTest from "./pages/tests/AuthTest";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path={"/"} element={<LandingPage/>}/>
              <Route path={"testscreens"} element={<TestScreen/>}/>
              <Route path={"login"} element={<LoginPage/>}/>
              <Route path={"header"} element={<ElHeader/>}/>
              <Route path={"footer"} element={<ElFooter/>}/>
              <Route path={"interest"} element={<ELInterest/>}/>
              <Route path={"splash"} element={<SplashPage/>}/>
              <Route path={"authtest"} element={<AuthTest/>}/>
              <Route path={"bgimage"} element={<BackgroundImage image={IMAGE}/>}/>
          </Routes>
      </BrowserRouter>

  );
}

export default App;
